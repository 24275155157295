<template>
  <base-material-card
    icon="mdi-face-agent"
    color="secondary"
    title="Agent Information"
  >
    <v-form
      ref="agentForm"
      lazy-validation
      @submit.prevent="updateAgent"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="agent.first_name"
            label="First Name *"
            :rules="[required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="agent.last_name"
            label="Last Name *"
            :rules="[required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="agent.ssn"
            label="SSN"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="agent.email"
            label="Email *"
            :rules="[required, email]"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="agent.company_id"
            label="Agency"
            item-text="name"
            item-value="id"
            :items="mixinItems.agencies"
            :loading="loadingMixins.agencies"
            :rules="[required]"
          />
        </v-col>
      </v-row>

      <v-btn
        fab
        absolute
        bottom
        right
        small
        type="submit"
        :loading="updating"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-form>
  </base-material-card>
</template>

<script>
  import { mapState } from 'vuex'
  import { Api } from '@/apis'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'

  export default {
    mixins: [
      fetchInitials([
        MIXINS.agencies,
      ]),
    ],

    data: () => ({
      required: value => !!value || 'This field is required.',
      email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      updating: false,
    }),

    computed: {
      ...mapState({
        agent: state => state.items.agent,
      }),
    },

    methods: {
      async updateAgent () {
        if (this.$refs.agentForm.validate()) {
          this.updating = true
          await new Api().put({
            url: 'agencies/agent/' + this.$route.params.id,
            request: this.agent,
          })
          this.updating = false
        }
      },
    },
  }
</script>
